<template>
  <div>
    <div class="content">
        <div class="title">在问难中助力</div>
        <div class="small_title">多样化的资源 / 多维度的评价 / 专业化的设计 / 大数据的支撑</div>
        <div class="contentDetail">
            <a-row class="detail1">
              <a-col class="col2" :span="10">
                <div class="title_s">在线备课</div>
                <div class="content_s">通过在线备课版块可以对学前准备、学习任务、学习活动、答题自测等环节的内容进行补充和调整。</div>
              </a-col>
              <a-col class="col3" :span="14"><div class="divImg"></div></a-col>
            </a-row>
            <div class="detail2">
              <a-col class="col3" :span="14">
                <div class="divImg"></div>
              </a-col>
              <a-col class="col2" :span="10">
                <div class="title_s">课程资源</div>
                <div class="content_s" style="margin-left:15px;">围绕教科书单元构建的“在线自主设计”融媒体动态资源库；教师可运用智慧，创新性地重组资源。</div>
              </a-col>
              
            </div>
            <div class="detail3">
              <a-col class="col2" :span="10">
                <div class="title_s">陪伴助学</div>
                <div class="content_s">师生共享、互动空间。教师可实时查看学生学习状态、步速、问题焦点，与学生一起讨论问题解决的路径，给出有针对性回复，对于那些超出解决能力的问题，可及时提交至“在线学习支持团队”，同他们一起研讨最佳解决方案。</div>
              </a-col>
              <a-col class="col3" :span="14"><div class="divImg"></div></a-col>
            </div>
            <div class="detail4">
              <a-col class="col3" :span="14"><div class="divImg"></div></a-col>
              <a-col class="col2" :span="10">
                <div class="title_s">名师之路</div>
                <div class="content_s" style="margin-left:15px;">教师专享空间，助力有志成为具有区域影响力的教师。教师所获得的专业支持包括：省级优质课、素质拓展课、论文发表、课题申报等，不一而足，让教师在前行路上，不再迷茫，不再无力，不再孤单。</div>
              </a-col>
              
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'teacher',
    data () {
        return {

        }
    }
}
</script>

<style scoped lang="less">
    ul,li{
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .content{
      width: 1140px;
      height: 800px;
      margin: auto;
      margin-top: 20px;
      text-align: center;
      color: #000000;
      .title{
        padding-top: 40px;
        font-size: 32px;
        font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
        font-weight: bold;
        line-height: 60px;
        letter-spacing: 20px;
      }
      .small_title{
       line-height: 30px;
       text-indent: -20px;
      }
      .contentDetail{
        display: flex;
        flex-wrap: wrap;
        .detail1,.detail2,.detail3,.detail4{
          width: 560px;
          height: 280px;
          margin-top: 20px;
          background: #fff;
          padding: 20px 20px 20px 20px;
            .col2{
              margin-top: 20px;
              color: #000000;
              .title_s{
                font-size: 22px;
                font-weight: bold;
                line-height: 34px;
              }
              .content_s{
                line-height: 26px;
                font-size: 14px;
                text-align: left;
              }
            }
          &:hover{
            transform:scale(1.02);
          }
        }
        &>div:nth-child(2n){
          margin-left: 20px;
        }
        .detail1{
          .col3{
            .divImg{
              margin-top: 18px;
              margin-left: 10px;
              height: 207px;
              background: url('../../assets/img/15.png');
              background-size: 100% 100%;
            }
          }
        }
        .detail2{
          .col3{
            .divImg{
              margin-top: 18px;
              height: 207px;
              background: url('../../assets/img/16.png');
              background-size: 100% 100%;
            }
          }
        }
        .detail3{
          height: 320px;
          .col3{
            .divImg{
              margin-top: 40px;
              margin-left: 10px;
              height: 207px;
              background: url('../../assets/img/17.png');
              background-size: 100% 100%;
            }
          }
        }
        .detail4{
          height: 320px;
          .col3{
            .divImg{
              margin-top: 40px;
              height: 207px;
              background: url('../../assets/img/18.png');
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  .tl{
    text-align: left;
  }
  .tg{
    text-align: right;
  }
</style>